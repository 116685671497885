.login__page {
    background: #001529; /* #eef2f6; */
    width: 100vw;
    height: 100vh;
    background-size: cover;
    position: relative;
    /* font: normal normal normal 0.875rem/1.3 "Roboto", "Trebuchet MS", Arial, Helvetica, sans-serif; */
}

.login__block {
    left: 50%;
    top: 50%;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 4px;
    min-width: 200px;
    padding: 3rem 3.5rem;
    text-align: center;
}

.login__block__caption {
    width: 300px;
    margin-bottom: 2rem;
    display: block;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    font-weight: bold;
    font-size: 18px;
}

.login__page__error {
    left: 50%;
    top: 10%;
    position: absolute;
    text-align: center;
    -webkit-transform: translate(-50%, -10%);
    transform: translate(-50%, -10%);
}

.login__page .login__block .login__block__button {
    background-color: rgb(74, 144, 226);
}
