/*@font-face {*/
/*    font-family: 'Roboto';*/
/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    src: url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');*/
/*    src: local('Roboto'),*/
/*    local('Roboto Regular'),*/
/*    local('Roboto-Regular')*/
/*}*/

.app {
    /*min-width: 100vw;*/
    /*min-height: 100vh;*/
    /*font-family: "Roboto"*/
    /*font: normal normal normal 0.875rem/1.3 "Roboto", "Trebuchet MS", Arial, Helvetica, sans-serif;*/
}
