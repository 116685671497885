.push-product-page {
    width: 100%;
    height: 100vh;
}

pre code {
    background-color: #eee;
    border: 1px solid #999;
    display: block;
    padding: 20px;
}