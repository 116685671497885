.import__block {
    left: 50%;
    top: 30%;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 10px;
    min-width: 200px;
    padding: 3rem 3.5rem;
    text-align: center;
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.5);
}

.import__block .import__block__button {
    background-color: rgb(74, 144, 226);
}

.progress__bar {
    left: 47%;
    top: 50%;
    position: absolute;
}