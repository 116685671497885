.view-error {
    color: red;
}

.view-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1000;
}

.view-loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1001;
}
